@import '~@core/styles/vars';

.ErrorModal {
  &-error-container {
    font-size: 11px;
    max-height: 400px;
    overflow: auto;
  }

  &-header {
    &-title {
      color: var(--red);
      font-size: 22px;
    }

    &-body {
      color: var(--gray60);
      margin-top: 3px;
    }
  }
}
