.Owlbert {
  @mixin high-density {
    @media only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      @content;
    }
  }

  &__absolute-container {
    z-index: 99;
  }

  &__container {
    height: 92px;
    position: relative;
    top: 11px;
    width: 116px;
  }

  &__face {
    background-image: url('./../../../../../../../../public/img/login/face.png');
    background-size: 116px 92px;
    height: 92px;
    left: 60%;
    margin-left: -64px;
    position: absolute;
    right: 5px;
    transform: scaleX(-1);
    width: 116px;

    @include high-density {
      background-image: url('./../../../../../../../../public/img/login/face@2x.png');
    }
  }

  &__eyes {
    background-image: url('./../../../../../../../../public/img/login/eyes.png');
    background-size: 100% 100%;
    height: 100%;
    left: 5px;
    opacity: 0;
    position: absolute;
    transform: scaleX(-1);
    transition: all 0.1s ease-out 0s;
    width: 100%;

    @include high-density {
      background-image: url('./../../../../../../../../public/img/login/eyes@2x.png');
    }
  }

  &__arm-down-left {
    background-image: url('./../../../../../../../../public/img/login/arm-down-left.png');
    background-size: 43px 25px;
    bottom: 2px;
    height: 25px;
    left: -34px;
    position: absolute;
    transition: all 0.3s ease-out;
    width: 43px;

    @include high-density {
      background-image: url('./../../../../../../../../public/img/login/arm-down-left@2x.png');
    }
  }

  &__arm-down-right {
    background-image: url('./../../../../../../../../public/img/login/arm-down-right.png');
    background-size: 43px 26px;
    bottom: 1px;
    height: 26px;
    position: absolute;
    right: -40px;
    transition: all 0.3s ease-out;
    width: 43px;

    @include high-density {
      background-image: url('./../../../../../../../../public/img/login/arm-down-right@2x.png');
    }
  }

  &__arm-up-left {
    background-image: url('./../../../../../../../../public/img/login/arm-up-left.png');
    background-position: 0 25px;
    background-repeat: no-repeat;
    background-size: 52px 41px;
    bottom: 11px;
    height: 41px;
    left: 10px;
    opacity: 0;
    position: absolute;
    transform: translateX(-34px) scale(0.8);
    transform-origin: 0 40px;
    transition:
      background-position 0.3s ease-out,
      transform 0.3s ease-out,
      opacity 0.1s linear 0.1s;
    width: 52px;

    @include high-density {
      background-image: url('./../../../../../../../../public/img/login/arm-up-left@2x.png');
    }
  }

  &__arm-up-right {
    background-image: url('./../../../../../../../../public/img/login/arm-up-right.png');
    background-position: 0 25px;
    background-repeat: no-repeat;
    background-size: 51px 41px;
    bottom: 11px;
    height: 41px;
    opacity: 0;
    position: absolute;
    right: -8px;
    transform: translateX(57px) scale(0.8);
    transform-origin: 0 40px;
    transition:
      background-position 0.3s ease-out,
      transform 0.3s ease-out,
      opacity 0.1s linear 0.1s;
    width: 51px;

    @include high-density {
      background-image: url('./../../../../../../../../public/img/login/arm-up-right@2x.png');
    }
  }

  &__eyes.password {
    opacity: 1;
    transition: opacity 0.1s ease-out 0.2s;
  }

  &__arm-down-left.password {
    opacity: 0;
    transform: translateX(40px) scale(0) translateY(-10px);
  }

  &__arm-up-left.password {
    background-position: 0 0;
    opacity: 1;
    transform: scale(1);
    transition:
      background-position 0.3s ease-out,
      transform 0.3s ease-out;
  }

  &__arm-down-right.password {
    opacity: 0;
    transform: translateX(-32px) scale(0) translateY(-8px);
  }

  &__arm-up-right.password {
    background-position: 0 0;
    opacity: 1;
    transform: scale(1);
    transition:
      background-position 0.3s ease-out,
      transform 0.3s ease-out;
  }
}
