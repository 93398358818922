@import '~@core/styles/vars';

.OnboardingCard {
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.8);
  border-radius: var(--sm, 10px);
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.05),
    0 2px 5px rgba(0, 0, 0, 0.02),
    0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  overflow: visible;
  padding: 24px;
  width: 100%;

  :global(.Input) {
    border: 1px solid rgba(0, 0, 0, 0.15);

    &:focus {
      border: 1px solid var(--blue) !important;
    }
  }

  &-title,
  &-subtitle {
    margin: 0;
  }

  &-override-border {
    border-color: transparent !important;
  }

  &-title {
    color: var(--color-text-default);
    font-size: 24px;
  }

  &_adTitle {
    font-size: 18px !important;
  }

  &-subtitle {
    color: var(--color-text-minimum);
    font-size: 16px;
    font-weight: var(--font-weight-normal);
    padding-bottom: 24px;
    padding-top: 5px;
  }

  &-owlbert-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
