@import '~@core/styles/vars';

.TermsAndConditions {
  bottom: 0;
  color: rgba(255, 255, 255, 0.3);
  display: block;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  gap: var(--sm);
  left: 0;
  margin-top: var(--md);
  max-width: 500px;
  padding: 12px 24px;
  position: absolute;
  text-align: center;

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  @media (max-height: 699px) {
    display: none;
  }
}
