@import '~@core/styles/vars.compat';

.TutorialObstacle {
  background: get('color' slate);
  border-radius: 6px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: #fff;
  margin: 70px auto;
  max-width: 580px;
  padding-top: 40px;
  position: relative;

  &-Close {
    align-items: center;
    background: none;
    border: 0;
    box-shadow: none;
    color: get(color 'shale');
    cursor: pointer;
    display: flex;
    font-size: ms(1);
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover,
    &:active,
    &:focus {
      color: get(color 'ivory');
    }

    &:focus {
      outline: 0;
    }
  }

  &-Top {
    padding-left: 40px;
    padding-right: 40px;
  }

  &-Title,
  &-Description {
    text-align: center;
  }

  &-Description {
    line-height: 1.5;
    max-width: 400px;

    // override default <p /> styles
    &.TutorialObstacle-Description.TutorialObstacle-Description {
      margin: 0 auto 20px;
    }
  }

  &-Buttons {
    display: flex;
    justify-content: center;

    :global(.Button + .Button) {
      margin-left: 10px;
    }
  }

  &-Img {
    display: flex;
    height: 190px;
    justify-content: center;
    margin-top: 30px;
    overflow: hidden;
    pointer-events: none;
    position: relative;

    &-Stars {
      bottom: 0;
      position: absolute;

      &-Star {
        animation: fadeIn 1s linear infinite;

        @keyframes fadeIn {
          0%,
          100% {
            opacity: 0.25;
          }

          50% {
            opacity: 1;
          }
        }

        &:nth-child(odd) {
          animation-delay: 0.5s;
        }
      }
    }

    &-Card {
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
    }
  }

  &:global(.paused) {
    circle,
    g {
      animation-play-state: paused;
    }
  }
}
