@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

// Vars
.TutorialTile {
  --TutorialTile-bg: var(--white);
  --TutorialTile-shadow: 0 1px 2px rgba(black, 0.05), 0 2px 5px rgba(black, 0.02); // Box_card
  --TutorialTile-link-color: var(--color-text-minimum);
  --TutorialTile-text-color: var(--color-text-default);

  @include dark-mode {
    --TutorialTile-bg: var(--gray20);
    --TutorialTile-shadow: 0 1px 2px rgba(black, 0.1), 0 2px 5px rgba(black, 0.1); // Box_card
  }
}

// Styles
.TutorialTile {
  background: var(--TutorialTile-bg);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: inherit;

  // This min-width value below determines certain responsive width properties that are set here:
  // https://github.com/readmeio/readme/blob/1523b5ab9810fd5a998b48cfb797cde5644672c2/packages/react/src/routes/Reference/style.scss#L100-L106
  // If this value changes at all, make sure to tweak those responsive width values!
  min-width: 230px;
  overflow: hidden;
  position: relative;
  transition: box-shadow var(--transition-slow) var(--transition-timing);

  &:hover {
    border-color: var(--color-input-border-hover);
  }

  &:active,
  &:focus {
    border-color: var(--color-input-border-active);
    box-shadow: 0 0 0 3px var(--color-input-border-focus);
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    outline: none;
  }

  &-Body {
    display: flex;
    padding: 10px;

    &-Avatar {
      border-radius: var(--border-radius-lg, 7.5px); // Fallback for backwards compatibility purposes
      flex: 0 0 auto;
      margin-right: 15px;

      > span {
        font-size: 30px;
      }
    }

    &-Text {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      text-align: left;

      &-Title {
        color: var(--TutorialTile-text-color);
        display: flex;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.1;
      }

      &-Action {
        color: var(--TutorialTile-link-color);
        font-size: 12px;

        &-Arrow {
          color: inherit;
          margin-left: 1px;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  &-Notification {
    align-items: center;
    background: get(color 'light-gray');
    border-top: 1px solid get(color 'ivory');
    display: flex;
    font-size: 13px;
    font-weight: 600;
    height: 40px;
    justify-content: center;

    &:focus {
      outline: none;
    }

    :global(.icon-eye-off) {
      align-items: center;
      background: get(color 'graphite');
      border-radius: 20px;
      color: #fff;
      display: inline-flex;
      font-size: 14px;
      height: 20px;
      justify-content: center;
      margin-right: 5px;
      width: 20px;
    }

    &-Dismiss {
      align-items: center;
      background: none;
      border: 0;
      color: get('color' slate);
      cursor: pointer;
      display: flex;
      font-family: inherit;
      font-size: 13px;
      font-weight: 600;
      margin-left: 10px;
      padding: 0 8px;
      position: relative;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }

      &:focus {
        outline: none;
      }

      &::before {
        background: #c4c4c4;
        border-radius: 4px;
        content: '';
        display: block;
        height: 4px;
        left: -4px;
        position: absolute;
        width: 4px;
      }
    }
  }
}
