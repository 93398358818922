@import '~@core/styles/vars';

.CTA {
  $block: &;

  max-width: 65px;
  position: relative;
 

  &-content {
    align-items: center;
    animation: fadeIn 1.5s ease-in;
    bottom: 15px;
    display: flex;
    flex-direction: row;
    height: 55px;
    justify-content: flex-start;
    padding: var(--sm);
    position: absolute;
    right: 30px;
    width: auto;
    z-index: 999;
    
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }

  &-emoji {
    display: block;
    font-size: 1.5em;
    height: 50px;
    left: -47px;
    padding: 0.15em;
    position: absolute;
    top: -6px;
    width: 50px;
  
  
    &-waving {
      $frames: 49;

      animation: gif steps($frames - 1) 2.5s infinite both paused;
      background: url('../../../../../../../../public/img/login/waving-hand.webp');
      background-repeat: no-repeat;
      background-size: $frames * 100%;
      opacity: 0;
      padding: 0;
      transform: scale(0.75);
      transform-origin: 75% 75%;
      transition: opacity 0.5s ease-in-out, transform 0.4s ease-in-out;

      &_active {
        animation-play-state: running;
        opacity: 1;
        transform: scale(1);
      }
    
    }

    @keyframes gif {
      0% {
        background-position: 0%;
      }

      100% {
        background-position: 100%;
      }
    }
}

&-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
 
}

&-title {
  color: white;
  font-size: 14px;
  opacity: 0.8;
}

&-demo{
  align-items: center;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 5px;
  opacity: 0.5;

  &:hover {
    color: white;
    opacity: 1;
  }
  

}

}


