.Onboarding {
  &-subtext {
    color: white;
    opacity: 0.5;

    &:hover {
      color: white;
      opacity: 0.8;
    }

    &:focus {
      color: white;
      opacity: 1;
    }

    &-no-hover {
      color: white;
      opacity: 0.5;
    }
  }
}
