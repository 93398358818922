// deprecated (use the Tooltip component)
@mixin tooltips {
  &[data-tooltip]:not([data-tooltip='']) {
    position: relative;

    &:hover::after,
    &:active::after,
    &:focus::after {
      opacity: 1;
    }

    &[data-tooltip-position='top']:hover::after,
    &[data-tooltip-position='bottom']:hover::after,
    &[data-tooltip-position='top']:active::after,
    &[data-tooltip-position='bottom']:active::after,
    &[data-tooltip-position='top']:focus::after,
    &[data-tooltip-position='bottom']:focus::after {
      transform: scale(1) translateX(-50%);
    }

    &[data-tooltip-position='left']:hover::after,
    &[data-tooltip-position='right']:hover::after,
    &[data-tooltip-position='left']:active::after,
    &[data-tooltip-position='right']:active::after,
    &[data-tooltip-position='left']:focus::after,
    &[data-tooltip-position='right']:focus::after {
      transform: scale(1) translateY(-50%);
    }

    &::after {
      background: #fff;
      border-radius: 6px;
      box-shadow:
        0 2px 8px rgba(0, 0, 0, 0.05),
        0 4px 15px rgba(0, 0, 0, 0.05);
      color: get('color' slate);
      content: attr(data-tooltip);
      font-size: 13px;
      font-weight: 400;
      left: 50%;
      line-height: 1.5;
      opacity: 0;
      padding: 5px 10px;
      pointer-events: none;
      position: absolute;
      text-decoration: none;
      top: calc(100% + 5px);
      transform: scale(0.95) translateX(-50%);
      transform-origin: 50% 100%;
      transition:
        opacity 0.3s cubic-bezier(0.16, 1, 0.3, 1),
        transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
      white-space: nowrap;
      z-index: 1000;
    }

    &[data-tooltip-position='top']::after,
    &[data-tooltip-position='right']::after,
    &[data-tooltip-position='left']::after {
      top: auto;
    }

    &[data-tooltip-position='top']::after {
      bottom: calc(100% + 5px);
      transform: scale(0.95) translateX(-50%);
      transform-origin: 50% 0;
    }

    &[data-tooltip-position='right']::after,
    &[data-tooltip-position='left']::after {
      top: 50%;
      transform: scale(0.95) translateY(-50%);
    }

    &[data-tooltip-position='right']::after {
      left: calc(100% + 5px);
      transform-origin: 0 50%;
    }

    &[data-tooltip-position='left']::after {
      left: auto;
      right: calc(100% + 5px);
      transform-origin: 100% 50%;
    }
  }
}
