@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

// Vars
.TutorialCard {
  --TutorialCard-bg: var(--white);
  --TutorialCard-bg-unpublished: var(--gray100);
  --TutorialCard-shadow: 0 1px 2px rgba(black, 0.05), 0 2px 5px rgba(black, 0.02); // Box_card
  --TutorialCard-text-color: var(--color-text-default);
  --TutorialCard-steps-color: var(--color-text-muted);

  @include dark-mode {
    --TutorialCard-bg: var(--gray20);
    --TutorialCard-bg-unpublished: var(--gray10);
    --TutorialCard-shadow: 0 1px 2px rgba(black, 0.1), 0 2px 5px rgba(black, 0.1); // Box_card
    --TutorialCard-text-color: white;
  }
}

// Styles
.TutorialCard {
  background: var(--TutorialCard-bg);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--TutorialCard-shadow);
  color: var(--TutorialCard-text-color);
  display: inline-flex;
  flex-direction: column;
  line-height: 1.5;
  max-width: 360px;
  min-width: 240px;
  position: relative;

  &_unpublished {
    background: var(--TutorialCard-bg-unpublished);

    .TutorialCard-Avatar,
    .TutorialCard-Title,
    .TutorialCard-Description,
    .TutorialCard-Steps {
      opacity: 0.75;
    }
  }

  &-Status {
    align-items: center;
    background: get('color' slate);
    border-radius: 25px;
    color: #fff;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 25px;
    padding: 0 8px;
    position: absolute;
    right: 10px;
    top: 10px;

    &_featured {
      background: get(color 'yellow');
      color: darken(get(color 'yellow'), 40%);
    }

    &_noemoji {
      margin: -10px -10px 10px auto;
      position: inherit;
    }

    &-Icon {
      margin-right: 3px;
    }
  }

  &-Top {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 20px;

    + .TutorialCard-Toolbar {
      box-shadow: inset 0 1px 0 var(--color-border-default);
    }
  }

  &-Avatar {
    margin-bottom: 15px;
  }

  &-Title {
    font-size: 16px;
    font-weight: 600;
  }

  &-Description {
    font-size: 14px;
    margin-right: 40px;
    margin-top: 5px;
    word-break: break-word;
  }

  &-Steps {
    color: var(--TutorialCard-steps-color);
    flex: 1 1 auto;
    font-size: 14px;
    margin-top: 10px;
  }

  &-Button {
    background: var(--recipe-button-color, get(color 'blue'));
    margin-top: 15px;

    &:hover {
      background: var(--recipe-button-color-hover, #027ff1);
    }

    &:active {
      background: var(--recipe-button-color-active, #0271d6);
    }

    &:focus {
      box-shadow: 0 0 0 3px var(--recipe-button-color-focus, rgba(17, 140, 253, 0.25));
    }

    &:disabled {
      background: var(--recipe-button-color-disabled, #70bafe);
    }
  }

  &-Toolbar {
    align-items: center;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    display: flex;
    justify-content: center;

    &-Wrapper {
      overflow-x: auto;
      white-space: nowrap;
    }

    &-Button,
    &-Arrow {
      align-items: center;
      background: none;
      border: 0;
      color: inherit;
      cursor: pointer;
      display: inline-flex;
      font-family: inherit;
      font-weight: 600;
      height: 39px;
      min-width: min-content;
      padding: 0 8px;
      position: relative;
      white-space: nowrap;

      &:focus {
        outline: none;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      + .TutorialCard-Toolbar-Button {
        margin-left: 4px;

        &::before {
          background: #c4c4c4;
          border-radius: 4px;
          content: '';
          display: block;
          height: 4px;
          left: -4px;
          position: absolute;
          width: 4px;
        }
      }
    }

    &-Button {
      font-size: 13px;
      text-decoration: underline;

      &:not(:disabled):hover,
      &:not(:disabled):active,
      &:not(:disabled):focus {
        text-decoration: none;
      }

      &_red {
        color: #e95f6a;

        &:disabled {
          color: rgba(#e95f6a, 0.5);
        }
      }
    }

    &-Arrow {
      font-size: 11px;
      opacity: 0.9;
      text-decoration: none;

      &:not(:disabled):hover,
      &:not(:disabled):active,
      &:not(:disabled):focus {
        opacity: 1;
      }
    }
  }
}
