@import '~@core/styles/vars';

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@mixin animate-fade-in {
  animation: fade-in 0.5s ease-out forwards;
}

@keyframes shimmer {
  to {
    background-position-x: -20%;
  }
}

@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(30deg);
  }

  100% {
    transform: translateX(100%) rotate(30deg);
  }
}

.MockHub {
  --MockHub-sidebar-width: 220px;
  --MockHub-sidebar-playground-width: 400px;
  --MockHub-border: 1px solid rgba(255, 255, 255, 0.2);

  background: rgba(255, 255, 255, 0.1);
  background-size: cover;
  border: var(--MockHub-border);
  border-radius: 7.5px 0 0 7.5px;
  border-right: 0;
  display: grid;

  // Grid for sidebar | content | playground column widths
  grid-template-columns: var(--MockHub-sidebar-width) minmax(500px, 1fr) var(--MockHub-sidebar-playground-width);
  grid-template-rows: auto auto 1fr;
  height: 80%;
  margin-right: -200px;
  max-height: 900px;
  overflow: hidden;
  position: relative;
  transform: rotate3d(0);
  transition-duration: 1000ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;

  &:hover::before {
    animation: shine 1s ease-out forwards; // Reduced duration to 0.5s
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 0.1) 25%,
      rgba(255, 255, 255, 0.25) 50%,
      rgba(255, 255, 255, 0.1) 75%,
      rgba(255, 255, 255, 0.05) 100%
    );
    content: '';
    height: 300%;
    left: -50%;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    top: -50%;
    transform: rotate(30deg);
    width: 200%;
    z-index: 99;
  }

  &-glow {
    background-image: radial-gradient(
      circle at 50% -20%,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.025)
    );
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  &-header {
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    color: var(--gray20);
    display: flex;
    flex-direction: row;
    grid-area: 1 / 1 / 2 / 4;
    height: 50px;
    padding: var(--sm);

    &_dark {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: white;
    }

    &-logo {
      height: 24px;
    }
  }

  &-navbar {
    $block: &;

    align-items: center;
    border-bottom: var(--MockHub-border);
    display: flex;
    flex-direction: row;
    gap: var(--md);
    grid-area: 2 / 1 / 3 / 4;
    height: 40px;
    padding: var(--sm);

    &-item {
      @include animate-fade-in;

      align-items: center;
      color: white;
      display: flex;
      font-weight: var(--font-weight);
      gap: var(--xs);
      white-space: nowrap;

      &-icon {
        opacity: 0.5;
      }

      &__active {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid transparent;
        border-radius: 7.5px;
        padding: 4px 8px;
      }
    }

    &_dark {
      #{$block}-item {
        color: var(--gray20);
      }

      #{$block}-item__active {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &-sidebar,
  &-sidebar * {
    cursor: default !important;
  }

  &-sidebar {
    background-color: initial;
    border-bottom-left-radius: inherit;
    border-right: 1px solid var(--color-border-default);
    height: 100%;
    opacity: 0;
    overflow-y: auto;
    transform: translateY(50px);
    transition: none;

    &_visible {
      background-color: white;
      opacity: 1;
      transform: translateY(0);
      transition:
        opacity 750ms ease-in,
        transform 0ms ease-in;
    }

    a:active {
      pointer-events: none;
    }

    a:focus {
      pointer-events: none;
    }

    nav {
      max-height: none;
      overflow: hidden;
    }
  }

  &-quicknav {
    box-sizing: border-box;
    padding: 15px 20px 10px;
    width: 100%;

    &-btn {
      align-items: center;
      background-color: var(--color-input-background);
      border: 1px solid var(--color-input-border);
      border-radius: var(--border-radius);
      box-sizing: border-box;
      color: var(--color-text-muted);
      cursor: default;
      display: flex;
      font-size: 10px;
      font-weight: var(--font-weight-bold);
      height: 30px;
      justify-content: space-between;
      padding: 1px 6px;
      transition: box-shadow var(--transition-fast) var(--transition-timing);
      width: 100%;
    }

    &-key {
      background: var(--gray100);
      border: 1px solid var(--color-border-default);
      border-radius: var(--border-radius);
      color: var(--QuickNav-key-color);
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 11px;
      padding: 1px 3px;
    }
  }

  :global(.rm-Sidebar) {
    display: flex !important;
    max-height: none;
  }

  :global(.disabled-warning) {
    display: none;
  }

  &-content {
    align-items: flex-start;
    background-color: initial;
    display: flex;
    grid-area: 3 / 2 / 4 / 3;
    height: 100%;
    opacity: 0;
    overflow-y: auto;
    padding: 30px 31px;
    transform: translateY(50px);
    transition:
      transform 0.5s ease-in-out,
      opacity 0.5s ease-in-out;
    width: 100%;

    &_visible {
      background-color: white;
      opacity: 1;
      transform: translateY(0);
      transition:
        opacity 750ms ease-in,
        transform 0ms ease-in;
    }
  }

  &-doc-title {
    font-size: 1.9em;
    font-weight: var(--font-weight-bold);
    line-height: 1.2;
    margin-bottom: var(--sm);
    margin-top: 0;
  }

  &-doc-subtitle {
    font-size: 13px;
  }

  &-divider {
    border: 0;
    border-top: 1px solid var(--color-border-default);
    margin: var(--sm) 0 0 0;

    &_with-margin {
      margin-top: var(--md);
    }
  }

  &-doc-params {
    background: var(--gray100);
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius);
    font-size: 0.85em;
    padding: 0.75em;
    width: 100%;

    &-param {
      color: var(--color-text-default);
      font-weight: var(--font-weight-bold);
    }

    &-type {
      color: var(--color-text-minimum);
    }

    &-required {
      color: var(--red);
    }

    &-input {
      background: white;
      border: 1px solid var(--color-border-default);
      border-radius: var(--border-radius);
      color: var(--gray70);
      padding: 0.25em 0.5em;
      white-space: nowrap;
      width: 200px;
    }
  }

  &-dateline {
    margin-top: var(--sm);
  }

  &-playground {
    background-color: initial;
    border-left: 1px solid var(--color-border-default);
    opacity: 0;
    padding: var(--sm) var(--md);
    transform: translateY(50px);
    transition: none;

    &_visible {
      background-color: white;
      opacity: 1;
      transform: translateY(0);
      transition:
        opacity 750ms ease-in,
        transform 0ms ease-in;
    }
  }

  &-shimmer {
    animation: shimmer 1.5s infinite linear;
    background: linear-gradient(
      -45deg,
      rgba(238, 238, 238, 0.25) 40%,
      rgba(250, 250, 250, 0.25) 50%,
      rgba(238, 238, 238, 0.25) 60%
    );
    background-position-x: 150%;
    background-size: 300%;
    bottom: 0;
    display: grid;

    // Shimmer grid should match MockHub's sidebar | content | playground column widths
    grid-template-columns: var(--MockHub-sidebar-width) minmax(500px, 1fr) var(--MockHub-sidebar-playground-width);
    grid-template-rows: auto;
    height: calc(100% - 90px);
    position: absolute;
    width: 100%;

    &-sidebar {
      border-right: var(--MockHub-border);
      height: 100%;
    }

    &-content {
      align-items: center;
      background-color: rgba(0, 32, 119, 0.03);
      border-radius: 7.5px;
      bottom: 12px;
      box-shadow: var(--box-shadow-request);
      color: white;
      display: flex;
      flex-direction: row;
      gap: 12px;
      height: auto;
      left: 12px;
      opacity: 0.9;
      padding: 18px;
      position: absolute;
      width: 172px;

      &-button {
        align-items: center;
        color: white;
        display: flex;
        flex-direction: row;
        font-weight: var(--font-weight);
        gap: 3px;
        justify-content: flex-start;
        opacity: .75;
        padding: 0 !important;

        &:hover {
          color: white !important;
          opacity: 1;
        }

        &:active,
        &:focus {
          border: none !important;
          box-shadow: none !important;
          outline: none !important;
        }
      }
    }

    &-playground {
      border-left: var(--MockHub-border);
    }
  }
}

.wrapper {
  backdrop-filter: blur(2px);
}
