@import '~@core/styles/vars.compat';

$TutorialEditorNavHeight: 60px;

.TutorialEditor {
  &-Nav {
    background: #242e34;
    flex: 0 0 $TutorialEditorNavHeight;
    padding-left: 10px;
    padding-right: 20px;
    position: relative;

    &-Tab {
      opacity: 0.5;

      &_active,
      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }

    &-Plus {
      margin-left: 0;

      &-ClearWrapper {
        margin-top: -40px;
        min-width: 201px;
        padding-top: 40px;

        &-Box {
          max-height: calc(100vh - #{ms(10) * 2} - 130px);
          overflow: auto;

          @media (max-width: 1450px) {
            max-height: calc(100vh - #{ms(2) * 2} - 130px);
          }
        }
      }
    }

    &-Copy {
      background: none;
      border: 0;
      color: white;
      cursor: pointer;
      height: 40px;
      opacity: 0.5;
      padding: 10px;
      width: 40px;

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }

      &:focus {
        outline: 0;
      }

      + :global(.Dropdown) {
        margin-left: 0; // override <Flex />
      }
    }

    :global {
      .Dropdown-content {
        transform: scale(0.97) translateY(-10px);
        transform-origin: 95% 0;
        transition:
          opacity 0.15s cubic-bezier(0.16, 1, 0.3, 1),
          transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);

        &_opened {
          transform: none;
        }
      }
    }

    &-Menu {
      &-List {
        $tutorialHeader: 60px;
        $tutorialNav: 60px;
        $onboardingPadding: 20px;
        $stepsHeight: 300px;
        $guideHeight: 99px;
        $guideMargin: 10px;

        background: var(--slate);

        @media (max-width: 768px) {
          max-height: calc(
            100vh - (#{ms(1)} * 2) - (#{$tutorialHeader + $tutorialNav + $onboardingPadding + $stepsHeight})
          );
        }

        &::before {
          display: none;
        }

        &_overflow {
          overflow-y: auto;
        }

        &_onboarding {
          max-height: calc(
            100vh - (#{ms(10)} * 2) -
              (#{$tutorialHeader + $tutorialNav + $onboardingPadding + $guideHeight + $guideMargin})
          );

          @media (max-width: 768px) {
            max-height: calc(
              100vh - (#{ms(1)} * 2) -
                (#{$tutorialHeader + $tutorialNav + $onboardingPadding + $stepsHeight + $guideHeight + $guideMargin})
            );
          }
        }
      }

      &-Method {
        flex: 0 0 40px; // manually set flex basis to fix menu layout RM-1024
      }

      &-ReferenceGuide {
        align-items: center;
        background: #3b4449; /* rgba(#fff, 0.1) on bg */
        border: 1px solid rgba(#fff, 0.15);
        border-radius: 6px;
        display: flex;
        left: 0;
        max-width: 290px;
        padding: 10px;
        position: absolute;
        right: 0;
        top: calc(100% + 10px);
        z-index: 1;

        &-Description {
          color: #fff;
          font-size: 14px;
          margin: 0;

          &-Button {
            background: rgba(#fff, 0.2);
            border: 0;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            display: block;
            font-size: 13px;
            margin-top: 5px;
            padding: 3px 6px;

            &:hover,
            &:active,
            &:focus {
              background: rgba(#fff, 0.25);
            }

            &:focus {
              outline: 0;
            }
          }
        }

        &-Avatar {
          flex: 0 0 auto;
          font-size: 25px;
          margin-right: 15px;
        }
      }
    }

    &-Button {
      max-width: 275px;

      &-Link-Icon {
        color: #fff;
        margin-left: 5px;
        transform: rotate(45deg);
      }
    }

    &-Button-Text,
    &-Menu-Text {
      margin-left: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-Controls {
    background: #242e34;
    flex: 0 0 auto;
    padding: 5px;

    &-ControlWrapper {
      align-items: center;
      background: get(color 'slate');
      border-radius: 6px;
      color: get(color 'shale');
      display: inline-flex;
      font-family: inherit;
      font-size: 15px;
      height: 25px;
      margin: 5px;
      padding-left: 8px;
      padding-right: 5px;

      &_select {
        appearance: none;
        position: relative;

        &::after {
          bottom: auto;
          color: get(color 'shale');
          content: '▼';
          display: block;
          font-size: 9px;
          pointer-events: none;
          position: absolute;
          right: ms(-2);
          top: auto;
        }
      }

      &-Select,
      &-Input {
        background: inherit;
        border: 0;
        color: get(color 'ivory');
        font-size: inherit;
        margin-left: 5px;

        &:focus {
          outline: 0;
        }
      }

      &-Select {
        appearance: none;
        padding-right: 20px;
      }

      &-Input {
        width: 75px;

        &::placeholder {
          color: get(color 'graphite');
        }
      }
    }

    &-Delete {
      background: none;
      border: 0;
      color: get(color 'red');
      cursor: pointer;
      font-family: inherit;
      font-size: 15px;
      margin: 5px;
      white-space: nowrap;

      &:focus {
        outline: 0;
        text-decoration: underline;
      }

      &:disabled {
        color: rgba(get(color 'red'), 0.5);
        cursor: not-allowed;
      }
    }
  }
}
