@import '~@core/styles/vars.compat';
@import '~@core/styles/tooltips';
@import '~@core/styles/mixins/dark-mode';

$modalOffset: #{ms(10) * 2};
$modalOffsetSm: #{ms(2) * 2};

.TutorialModal {
  @include dark-mode {
    --md-code-background: var(--gray10);
  }

  height: 100%;
  max-width: 1200px;
  width: 100%;

  * {
    @include tooltips;
  }

  &_created {
    height: auto;
    max-width: 580px; // same as obstacle
    width: auto;
  }

  * {
    box-sizing: border-box;
  }

  &-Obstacle {
    margin: 0;
  }

  &-Form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-Row {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;

    @media (max-width: 1028px) {
      flex-direction: column;
    }
  }

  &-Col {
    background: #242e34;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-y: auto;
    position: relative;

    &_steps {
      background: #242e34;
      border-right: 1px solid rgba(white, 0.1);
      overflow-y: auto;
      padding: 20px;

      @media (min-width: 1029px) {
        flex: 0 0 400px;
      }

      @media (max-width: 1028px) {
        border-bottom: 1px solid rgba(white, 0.1);
        border-right: 0;
      }
    }

    &_review {
      background: #242e34;
      padding: 20px;

      &:first-child {
        border-right: 1px solid rgba(white, 0.1);
      }

      @media (min-width: 1029px) {
        &:first-child {
          flex: 0 0 400px;
        }
      }

      @media (max-width: 1028px) {
        &:first-child {
          border-bottom: 1px solid rgba(white, 0.1);
          border-right: 0;
          flex: 0 0 auto;
          min-height: 430px; // for emoji picker
        }
      }
    }

    &_singleEditor :global(.CodeEditor.CodeEditor.CodeEditor) {
      border-top: 0;
      max-height: none;
    }

    // title input
    &-Input {
      // replacing these vars here b/c sometimes the modal is a child of the body
      // and other times a child of .App and I have no idea why and who has time to care
      --Input-background: var(--gray20);
      --Input-bg-focus-minimal: var(--gray30);
      --Input-border-hover: #60686d; // Hex equivalent of rgba(white, 0.2) over --color-input-background

      color: white;
      flex: 0 0 auto;
      margin-bottom: 10px;
    }

    &-Wrapper {
      background: get(color 'slate');
      border-radius: 6px;
      padding: 30px;
      pointer-events: none;

      + .TutorialModal-Col-Wrapper {
        margin-top: 10px;
      }

      &-Caption {
        color: get(color 'shale');
      }

      &-Card {
        width: 100%;
      }
    }

    &-More {
      align-self: flex-end;
      color: #fff;
      display: inline-block;
      font-weight: 600;
      margin-top: 15px;
      opacity: 0.6;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: #fff; // override readme
        opacity: 0.75;

        .TutorialModal-Col-More-Icon {
          opacity: 0.75;
          transform: rotate(-45deg) translateX(2px);
        }
      }

      &-Icon {
        margin-left: 5px;
        opacity: 0.5;
        transform: rotate(-45deg);
        transition:
          opacity 0.3s cubic-bezier(0.16, 1, 0.3, 1),
          transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
      }
    }
  }

  &-Nav {
    background: #242e34;
    border-bottom: 1px solid rgba(white, 0.1);
    color: #fff;
    flex: 0 0 auto;
    overflow-x: auto;
    padding-left: 20px;
    padding-right: 10px;

    &-Wrapper {
      padding: 20px 0;
    }

    :global(.Title) {
      align-items: center;
      display: flex;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:global(.markdown-body) > * {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }

    &-Avatar {
      flex: 0 0 auto;
    }

    &-Description {
      color: get(color 'chalk');
      font-size: 14px;

      // override markdown
      p {
        margin: 0;
      }
    }

    &-Close {
      align-items: center;
      background: none;
      border: 0;
      box-shadow: none;
      color: get(color 'shale');
      cursor: pointer;
      display: flex;
      font-size: ms(1);
      margin-left: 10px;

      &:hover,
      &:active,
      &:focus {
        color: get(color 'ivory');
      }

      &:focus {
        outline: 0;
      }
    }

    &-Tab {
      align-items: center;
      background: none;
      border: 0;
      color: #fff;
      display: flex;
      font-size: ms(1);
      font-weight: 600;
      height: 59px;
      opacity: 0.5;
      padding: 0;
      position: relative;

      @media (max-width: 520px) {
        font-size: ms(0);
      }

      &:not(.TutorialModal-Nav-Tab_active) {
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          opacity: 0.75;
        }
      }

      &:focus {
        outline: 0;
      }

      &_active {
        box-shadow: inset 0 -3px 0 get(color 'graphite');
        opacity: 1;
      }

      &-Icon {
        align-items: center;
        background: get(color 'graphite');
        border-radius: 6px;
        color: #fff;
        display: flex;
        font-size: 12px;
        height: 20px;
        justify-content: center;
        margin-right: 10px;
        width: 20px;
      }
    }
  }

  @media (max-width: 1028px) {
    height: auto;
  }
}

.TutorialModal {
  // override menu to fit within modal
  :global(.Dropdown-content .Dropdown-content .Menu) {
    max-height: calc(50vh);
    overflow-y: auto;
  }

  // override code editor
  // theming and layout
  :global {
    .CodeEditor {
      background: #242e34;
      flex: 1 1 60%;
      font-size: 14px;
      overflow: auto;
      white-space: pre;

      &:last-child {
        border-top: 1px solid rgba(white, 0.1);
        flex: 1 1 40%;
        max-height: 50%;
        padding-top: 10px;
      }

      .cm-s-material-palenight.CodeMirror,
      .cm-s-material-palenight .CodeMirror-gutters {
        background: #242e34;
      }

      .cm-s-material-palenight .CodeMirror-guttermarker,
      .cm-s-material-palenight .CodeMirror-guttermarker-subtle,
      .cm-s-material-palenight .CodeMirror-linenumber {
        color: #9091b2;
      }

      .CodeMirror {
        height: 100%;
        padding-bottom: 10px;
      }
    }
  }
}

:global {
  // readOnly mode
  .CodeEditor-Input_readonly {
    .cm-linerow,
    &:not(:hover) .cm-overlay > span,
    &:not(:hover) .cm-overlay > p {
      transition: none;
    }

    .cm-linerow.cm-overlay .cm-lineNumber {
      color: #bebcd7;
    }

    .CodeMirror {
      overflow: visible;

      .variable-underline {
        border-bottom: 1px solid rgba(#fff, 0.2);

        &:hover,
        &:active,
        &:focus {
          border-bottom-color: rgba(#fff, 0.4);
        }
      }

      select {
        appearance: none;
        background: transparent;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNoZXZyb24tZG93biI+PHBvbHlsaW5lIHBvaW50cz0iNiA5IDEyIDE1IDE4IDkiPjwvcG9seWxpbmU+PC9zdmc+');
        background-position: 100% 50%;
        background-repeat: no-repeat;
        background-size: 15px;
        border: 1px solid rgba(#fff, 0.2);
        border-radius: 5px;
        position: relative;
        transition: box-shadow 0.15s cubic-bezier(0.16, 1, 0.3, 1);

        &:hover,
        &:active,
        &:focus {
          border-bottom-color: rgba(#fff, 0.4);
        }

        &:focus {
          outline: 0;
        }
      }
    }
  }

  // not readOnly highlighting
  .TutorialEditor-Nav + .CodeEditor:not(.CodeEditor-Input_readonly) {
    .CodeMirror-focused .CodeMirror-code > div:first-child .CodeMirror-line > span::after {
      opacity: 0;
    }

    // this removes what would’ve been the relative parent for the overlay
    .CodeMirror-line,
    .CodeMirror-code > div {
      position: static !important;
    }

    // this is to provide a new (higher-level) relative parent for the overlay
    // :first-child b/c we only need one overlay
    // ::after must be at this level to match the depth of the next selector for z-index to work
    .CodeMirror-code {
      position: relative;
    }

    // overlay make everything less bright
    // :first-child b/c we only need one overlay
    // ::after must be at this level to match the depth of the next selector for z-index to work
    .CodeMirror-code > div:first-child .CodeMirror-line > span::after {
      background: rgba(#242e34, 0.5);
      content: '';
      display: block;
      height: 100%;
      inset: 0;
      pointer-events: none;
      position: absolute;
      transition: opacity 0.15s cubic-bezier(0.16, 1, 0.3, 1);
      width: 100%;
    }

    // be on top of the overlay
    .CodeEditor-Highlight {
      position: relative;
      z-index: 1;
    }
  }
}
