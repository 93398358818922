@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

// Vars
.TutorialReferencePicker {
  --TutorialReferencePicker-bg: var(--gray90);
  --TutorialReferencePicker-check-color: var(--gray30);
  --TutorialReferencePicker-text-color: var(--gray20);

  @include dark-mode {
    --TutorialReferencePicker-bg: var(--gray20);
    --TutorialReferencePicker-check-color: white;
    --TutorialReferencePicker-text-color: white;
  }
}

// Styles
.TutorialReferencePicker {
  background: var(--TutorialReferencePicker-bg);
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius);
  box-shadow: none;
  flex: 1 1 auto;
  max-width: none;
  overflow-y: auto;

  @media (min-width: 1029px) {
    width: 339px;
  }

  @media (max-width: 768px) {
    max-width: calc(100vw - 101px);
  }

  &-Header {
    align-items: center;
    color: var(--gray60);
    display: flex;

    &-BackArrow {
      align-items: center;
      display: inline-flex;
      font-size: 12px;
      height: 20px;
      opacity: 0.5;
      width: 22px;
    }
  }

  &-Item {
    align-items: center;
    display: flex;
    white-space: nowrap;

    &-Name {
      align-items: center;
      color: var(--TutorialReferencePicker-text-color);
      display: inline-flex;
      overflow: hidden;

      &-Method {
        flex: 0 0 auto;
        margin-right: 10px;
      }

      &-Text {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &-Check {
    color: var(--TutorialReferencePicker-check-color);
    flex: 0 0 auto;
    font-size: 12px;
    margin-right: 10px;
    opacity: 0.25;
    transition: opacity var(--transition-slow) var(--transition-timing);

    &_selected {
      color: var(--green);
      opacity: 1;
    }
  }
}
