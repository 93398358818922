@import '~@core/styles/vars.compat';

.TutorialStepNav {
  display: flex;
  flex-direction: column;

  &-Button {
    margin-top: 10px;
    width: 100%;
  }
}
