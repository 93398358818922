@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

// Vars
.TutorialStep {
  --RecipeStep-bg: white;
  --RecipeStep-bg-hover: var(--gray100);
  --RecipeStep-bg-closed: var(--gray20);
  --RecipeStep-bg-hover: var(--gray30);

  @include dark-mode {
    --RecipeStep-bg: var(--gray15);
  }
}

// Styles
.TutorialStep {
  background: var(--RecipeStep-bg);
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  transition: box-shadow var(--transition-slow) var(--transition-timing);

  &:focus:not(.TutorialSteps_open) {
    border-color: var(--color-input-border-active);
    box-shadow: 0 0 0 3px var(--color-input-border-focus);
    outline: 0;
  }

  // open/close styling
  &_open {
    @include dark-mode {
      color: var(--white);
    }
  }

  &_close {
    background: var(--RecipeStep-bg-closed);
    cursor: pointer;

    &:hover {
      background: var(--RecipeStep-bg-hover);

      .TutorialStep-Header-Title {
        opacity: 1;
      }
    }

    .TutorialStep-Header {
      border-radius: var(--border-radius);
      box-shadow: none;

      &-Title {
        color: var(--white);
        opacity: 0.75;
      }
    }

    .TutorialStep-Body {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  + .TutorialStep {
    margin-top: 10px;
  }

  &-Header,
  &-Body,
  &-LineNumbers {
    padding: 10px;
  }

  &-Header {
    align-items: center;
    box-shadow: inset 0 -1px 0 var(--color-border-default);
    display: flex;

    &-Input {
      flex: 1 1 auto;
      min-width: 0;

      &[readonly] {
        cursor: pointer;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      + .TutorialStep-Header-Button {
        margin-left: 4px;
      }
    }

    &-Title {
      align-items: center;
      display: inline-flex;
      font-size: 1em;
      font-weight: 600;
      line-height: 1.2;
      margin: 0;
      padding: 10px 0.87em;
    }

    &-Button {
      font-size: 18px;
      width: 30px;

      &:disabled {
        display: none;
      }
    }
  }

  &-Body {
    &-Description {
      line-height: 1.2;
      overflow-wrap: break-word;
      padding: ms(-1);
      white-space: pre-wrap; // fallback for Safari; pre-wrap has similar behavior
      white-space: break-spaces;

      &:empty {
        display: none;
      }

      &:global(.markdown-body) {
        white-space: unset;

        ul,
        ol {
          padding-left: 1em;
        }

        :global(.CodeTabs-inner) pre {
          margin-bottom: 0 !important;
        }
      }
    }

    &-Textarea {
      height: 120px;
      line-height: 1.4;
    }
  }

  &-LineNumbers {
    color: var(--color-text-muted);
    padding-top: 0;

    span {
      font-size: 13px;
      white-space: nowrap;
    }

    label {
      align-items: center;
      background-color: var(--color-input-background);
      border: 1px solid var(--color-input-border);
      border-radius: var(--border-radius);
      display: inline-flex;
      height: 30px;
      justify-content: space-between;
      width: 100%;

      &:focus-within {
        border-color: var(--color-input-border-active);
        box-shadow: 0 0 0 3px var(--color-input-border-focus);
      }

      input {
        background: none;
        border: none;
        margin-left: 10px;
        padding: 0;

        &:focus {
          background: none;
          border: none;
          box-shadow: none;
        }
      }
    }

    // tooltips
    &-Tooltip {
      align-items: center;
      background: get(color 'shale');
      border-radius: 15px;
      color: #fff;
      display: flex;
      font-size: 7px;
      height: 15px;
      justify-content: center;
      margin-right: 5px;
      text-align: center;
      width: 15px;

      &::after {
        font-family: var(--font-family);
      }
    }
  }

  // footer (isReadOnly)
  &-Footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px;

    &-Button {
      :global(.fa-arrow-circle-down) {
        margin-left: 4px;
        transform: rotate(-90deg);
      }
    }
  }

  // isReadOnly inputs/textarea
  [readonly] {
    background: transparent;
    border-color: transparent;
    color: #fff;

    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
}

// override readme styles
:global(#react-app-content-container) header.TutorialStep-Header,
:global(section#content) header.TutorialStep-Header {
  border-bottom: 0;
  margin: 0;
  padding: 10px;
}
