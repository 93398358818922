@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

// Vars
.TutorialReview {
  --RecipeReview-bg: white;
  --RecipeReview-text-color: var(--gray20);

  @include dark-mode {
    --RecipeReview-bg: var(--gray20);
    --RecipeReview-text-color: white;
  }
}

// Styles
.TutorialReview {
  background: var(--RecipeReview-bg);
  border-radius: var(--border-radius);
  color: var(--RecipeReview-text-color);
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  max-height: 100%;
  overflow: visible;

  &-Fieldset {
    border: 0;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    margin: 0;
    overflow: auto;
    padding: 10px;

    &_emojiPicker {
      overflow: visible;
    }

    &_endpoints {
      flex: 1 1 auto;
    }

    + .TutorialReview-Fieldset {
      border-top: 1px solid var(--color-border-default);
    }

    &-Header {
      flex: 0 0 auto;
    }

    &-Avatar {
      cursor: pointer;

      &_empty :global(.Avatar-Text) {
        transform: scale(0.5);
      }

      &_nobg {
        border: 1px solid var(--color-border-default);
      }
    }

    &-ClearWrapper {
      margin-top: -40px;
      padding-top: 40px;
    }

    &-Box {
      max-height: 325px;
      max-width: 325px;
      overflow-y: auto;
      padding: 5px;
    }

    &-Label {
      font-size: 14px;
      font-weight: 600;
    }

    &-Input,
    &-Textarea {
      width: 100%;
    }

    &-Textarea {
      height: 120px;
      line-height: 1.2;
    }
  }
}
