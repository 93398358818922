@import '~@core/styles/vars';

.OnboardingLayout {
  $block: &;

  height: 100vh;
  padding: 2rem;

  &-layout {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  // In single card view, we want mock hub to sit positioned to the right of viewport
  &-layout_single-card {
    #{$block}-card-container {
      padding-left: 10%;
      width: 90%;
    }
    #{$block}-mock-hub-container {
      width: 10%;
    }
  }

  &-fancy-card,
  &-fancy-card-content {
    height: 100%;

    // Adds a grid background to the card
    &_show-background-grid &::after {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0.04) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(255, 255, 255, 0.04) 1px, transparent 1px);
      background-size: 32px 32px;
      content: '';
      height: 100%;

      // Use mask for fading effect
      mask-image: linear-gradient(to left, transparent, black 150%);
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -20;
    }

    @media (max-width: $ipad-mini-portrait) {
      padding: var(--sm);
    }
  }

  &-card-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--md);
    transition: width 0.5s ease-in-out;
    width: 60%;
  }

  &-mock-hub-container {
    align-items: center;
    display: flex;
    height: 100%;
    perspective: 2000px;
    transform-style: preserve-3d;
    transition: width 0.5s ease-in-out;
    width: 40%;
  }

  &-mock-hub-owlbert {
    bottom: 10vh;
    position: fixed;
    right: -1px;
    width: 100px;
  }

  @media (max-width: $ipad-mini-portrait) {
    &_single-card {
      #{$block}-card-container {
        padding-left: 0;
        width: 100%;
      }

      #{$block}-mock-hub-container {
        display: none;
      }
    }

    #{$block}-card-container {
      padding-left: 0;
      width: 100%;
    }

    #{$block}-mock-hub-container,
    #{$block}-mock-hub-owlbert {
      display: none;
    }
  }

  &-logo {
    height: 30px;
    left: 30px;
    position: absolute;
    top: 30px;
  }

  @media (max-width: $ipad-mini-portrait) {
    padding: 0;
  }
}

.syncing-text {
  color: var(--text-color-minimum);
  opacity: 0.5;
}
