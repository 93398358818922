@import '~@core/styles/vars';

.SignupForm {
  display: flex;
  flex-direction: column;

  :global(.FormGroup + .FormGroup) {
    margin-top: 20px;
  }

  &-skip-btn {
    margin-top: var(--md);
  }

  &-divider {
    border: 0;
    border-top: 1px solid var(--color-border-default);
    margin: 24px -24px;
  }

  &-form-group-col {
    flex: 1;
    flex-wrap: wrap !important;
  }

  &-nav-toggle-field {
    align-items: flex-start;
    background-color: white;
    border: 1px solid var(--color-border-default);
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    :global(.FormGroup-input) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    :global(.SignupForm-label) {
      color: var(--color-text-muted);
      font-size: var(--icon-md);
      font-weight: var(--font-weight);
    }

    &:hover {
      border-color: rgba(black, 0.2);
    }
  }

  &-nav-toggle-field-active {
    align-items: flex-start;
    background-color: var(--blue50);
    border: 1px solid var(--color-border-default);
    border-radius: 50px;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    transition: background-color 0.1s ease;

    :global(.FormGroup-input) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    :global(.SignupForm-label) {
      color: var(--color-text-muted);
      font-size: var(--icon-md);
      font-weight: var(--font-weight);
    }

    &:hover {
      border-color: rgba(black, 0.2);
    }
  }

  &-nav-toggle-field-zero-margin {
    margin-top: 0 !important;
  }

  &-nav-toggle-field-custom {
    padding: 4px 8px;

    :global(.FormGroup-input) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    :global(.SignupForm-label) {
      color: var(--color-text-muted);
      font-size: 14px;
      font-weight: var(--font-weight);
    }
  }

  &-image {
    border: 1px solid var(--color-border-default);
  }

  &-toggle {
    display: none;
    margin-right: 10px !important;
    opacity: 0;
  }

  &-color-picker-wrapper {
    border: 1px solid var(--color-border-default);
    box-shadow: var(--box-shadow-pill);
    display: block !important;
  }

  &-icon {
    color: var(--color-text-muted) !important;
    font-size: var(--icon-md);
    opacity: 0.6;
  }

  &-icon-active {
    color: white !important;
    font-size: var(--icon-md);
    opacity: 0.6;
  }

  &-label-icon {
    display: flex;
    flex-direction: row;
    gap: 5px;
    position: relative;
  }

  &-label {
    text-wrap: nowrap;
  }

  &-or-separator {
    align-items: center;
    color: var(--color-text-minimum);
    display: flex;
    gap: var(--md);
    padding: 14px 0;
    text-align: center;
    width: 100%;

    &-line {
      background-color: var(--color-text-minimum);
      height: 1px;
      opacity: 0.2;
      width: 100%;
    }
  }

  &-main-container {
    --flex-gap: 0;
  }

  &-form-group-margin-top {
    margin-top: var(--md);
  }

  &-flex-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: var(--md);
    width: 100%;
  }

  &-width-100 {
    width: 100%;
  }

  &-logo-group {
    margin-top: var(--md);
    width: 100%;

    :global(.Button_primary) {
      border: 1px solid rgba(0, 0, 0, 0.15) !important;
    }
  }

  &-oas-button-group {
    border-color: rgba(0, 0, 0, 0);
    margin-bottom: var(--sm);
    width: 100%;

    :global(.Button_primary) {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid var(--blue) !important;
      color: var(--blue) !important;
      flex: 1;

      &:active {
        box-shadow: none !important;
        outline: none !important;
      }

      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    :global(.Button_secondary) {
      background: transparent !important;
      border: 0;
      border-bottom: 2px solid transparent !important;
      color: var(--color-text-muted);
      flex: 1;

      &:active {
        box-shadow: none !important;
        outline: none !important;
      }

      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }

  &-upload-file {
    overflow: hidden;

    &-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-form-group-error-with-link {
    display: block;
    margin-top: var(--sm);
  }

  &-submit-btn-description {
    color: var(--color-text-minimum);
    margin-top: var(--sm);
    text-align: center;
  }

  &-forgot-password {
    :global(.Flex) {
      width: 100%;
    }
  }

  &-success-message {
    color: var(--blue);
    font-size: 14px;
    font-weight: var(--font-weight-bold);
  }

  &-color-picker {
    display: block;
  }

  &-superhub-toggle {
    background: linear-gradient(180deg, var(--yellow70) 0%, var(--yellow) 100%);
    border-color: rgba(black, 0.2);
    border-radius: var(--sm);
    box-shadow:
      0 1px 8px 0 rgba(var(--yellow-rgb), 0.7),
      0 2px 4px 0 rgba(black, 0.05),
      0 1px 2px 0 rgba(black, 0.05),
      0 -1px 2px 0 rgba(black, 0.1) inset,
      0 1px 0 0 rgba(white, 0.25) inset;
    color: var(--yellow20);
    filter: saturate(1.25);
    margin-bottom: 10px;
    margin-top: -10px;
    padding: 5px 10px;
    width: max-content;

    // Override Toggle-label_muted styles
    :global(.Toggle-label_muted) {
      color: var(--yellow20) !important;
    }

    // Override Toggle_display styles
    :global(.Toggle-display) {
      background: var(--yellow90) !important;
      border: 1px solid rgba(#7d5b00, 0.2) !important;
    }

    // Toggle-display-icon
    :global(.Toggle-display-icon) {
      color: var(--yellow20) !important;
    }

    :global(.Toggle-display::after) {
      background: var(--yellow100) !important;
    }

    :global(.Toggle-input:checked + .Toggle-display) {
      background: var(--yellow40) !important;
    }
  }

  &-superhub-toggle-label {
    align-items: center;
    display: flex;
    gap: var(--xs);
  }

  &-superhub-toggle-icon {
    opacity: 0.5;
  }
}



// Hide Signup reCaptcha badge
:global(.grecaptcha-badge) {
  visibility: hidden;
}
