@import '~@core/styles/vars.compat';

.TutorialResponse-Tooltip {
  bottom: 10px;
  color: #fff;
  cursor: pointer;
  opacity: 0.5;
  right: 10px;
  z-index: 1;

  // override default
  &.TutorialResponse-Tooltip[data-tooltip]:not([data-tooltip='']) {
    position: absolute;
  }

  &::after {
    font-family: var(--font-family);
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 1;
  }
}
